import React, { Component } from "react";
import "./index.css";

// This component renders the gray shaded header of the page.

class ReceiptHeader extends Component {
	render() {
		let { receipt } = this.props;
		return (
			<header className="App-header">
				<p>Demo Receipt {receipt.sales_order_number}</p>
			</header>
		);
	}
}

export default ReceiptHeader;
