import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import geolocator from 'geolocator';
import geodistance from 'geo-distance';

import ReceiptBody from "../component/Receipt/";
import Header from "../component/Header/";

import "./DemoReceipt.css";

class DemoReceipt extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			found: false,
			coords: { latitude: 0, longitude: 0 },
			gpsEnabled: false,
			gpsLocating: true,
			receipt: {
				"id": 1,
				"qr_uuid": "1234567891",
				"upos_outlet_code": "DEMO1",
				"sales_order_number": "DEMO123-133",
				"receipt_timestamp": moment().format('YYYY-MM-DD hh:mm:ss'),
				"subtotal_amount": 15,
				"tax_amount": 1,
				"total_amount": 15,
				"is_taxable": 1,
				"is_service_chargeable": 1,
				"price_inclusive_tax": 1,
				"amount_received": 50,
				"discount_type": 0,
				"discount_rate": 10,
				"discount_amount": 15,
				"change_amount": 35,
				"header_text1": "Brand Name Pte Ltd",
				"header_text2": "Blk 123, Ang Mo Kio St 12.",
				"header_text3": "Singapore 123456",
				"header_text4": "Gst Reg No: 1234567899E",
				"footer_text1": "Thank you for your patronage!",
				"footer_text2": "Please come again!",
				"created_at": "2021-06-16T03:38:15.000Z",
				"updated_at": "2021-06-16T03:38:15.000Z",
				"ReceiptItems": [
					{
						"id": 1,
						"sales_order_number": "112233",
						"item_index": "1",
						"item_description": "Cake",
						"item_quantity": 1,
						"unit_price": 1.7,
						"subtotal_price": 1.7,
						"item_taxable": 0,
						"created_at": "2021-06-16T03:38:15.000Z",
						"updated_at": "2021-06-16T03:38:15.000Z"
					},
					{
						"id": 2,
						"sales_order_number": "112233",
						"item_index": "2",
						"item_description": "Donut",
						"item_quantity": 1,
						"unit_price": 1.6,
						"subtotal_price": 1.6,
						"item_taxable": 0,
						"created_at": "2021-06-16T03:38:15.000Z",
						"updated_at": "2021-06-16T03:38:15.000Z"
					}
				]
			},
		};
	}

	componentDidMount() {
		const geoOptions = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumWait: 10000,     // max wait time for desired accuracy
			maximumAge: 0,          // disable cache
			desiredAccuracy: 30,    // meters
			fallbackToIP: true,     // fallback to IP if Geolocation fails or rejected
			addressLookup: false,    // requires Google API key if true
			timezone: false,         // requires Google API key if true
			// map: "map-canvas",      // interactive map element id (or options object)
			staticMap: false         // get a static map image URL (boolean or options object)
		};

		geolocator.config({
			language: "en",
			google: {
				version: "3",
				key: "AIzaSyAFCjX8XpBDWst0KI54eH2yAFmeN3AZNtA"
			}
		});
		geolocator.locate(geoOptions, (err, location) => {
			if (err) {
				console.log('There was an error.');
				return console.log(err);
			}
			else {
				let gpsEnabled = true;
				let gpsLocating = parseInt(location.coords.accuracy) < 1000 ? true : false;
				this.setState({ coords: location.coords, gpsEnabled, gpsLocating });
				console.log(location.coords.accuracy);
				console.log(location.coords);
			}
		});
	}

	render() {
		let { receipt, coords, gpsEnabled, gpsLocating } = this.state;

		return (
			<div className="App">
				<Header receipt={receipt} />
				<ReceiptBody receipt={receipt} />
				<div>
					<p>{gpsEnabled ? `You are at ${coords.latitude}, ${coords.longitude}` : "GPS Disabled."}</p>
					<p>{gpsLocating ? `Accuracy: ${coords.accuracy ? coords.accuracy: "-"}m` : `Poor location accuracy: ${coords.accuracy}m`}</p>
					<div id="map-canvas" style={{ width: '100%', height: '400px' }}></div>
				</div>
			</div>
		);
	}
}

export default withRouter(DemoReceipt);
