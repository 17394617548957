import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import DefaultReceipt from "./pages/DefaultReceipt";
import DemoReceipt from "./pages/DemoReceipt";
import UposReceipt from "./pages/UposReceipt";
import Invalid from "./pages/Invalid";

import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Switch>
				<Route path={["/0/:id"]} component={DemoReceipt} />
				<Route path={["/1/:id"]} component={UposReceipt} />
				<Route path={["/:id"]} component={DefaultReceipt} />
				<Route path="/" component={Invalid} />
			</Switch>
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
