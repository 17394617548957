import React, { Component } from "react";
import "./index.css";

// This component renders the white part of the page that contains the receipt itself.

class ReceiptBody extends Component {
	render() {
		let { receipt } = this.props;
		return (
			<div>
				<h1 className="headerText1">{receipt.header_text1}</h1>
				<p>{receipt.header_text2}</p>
				<p>{receipt.header_text3}</p>
				<p>{receipt.header_text4}</p>
				<p>
					<u>
						<b>TAX INVOICE</b>
					</u>
				</p>
				<p>Date: {receipt.receipt_timestamp}</p>
				<p>SN: {receipt.sales_order_number}</p>
				<table className="receiptTable">
					<thead>
						<tr className="underline">
							<th>Qty</th>
							<th style={{ width: "200px" }}>Product/Item</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
						{receipt.ReceiptItems.map((item) => {
							return (
								<tr className="rowItem">
									<td>{item.item_quantity}</td>
									<td>{item.item_description}</td>
									<td>{item.subtotal_price.toFixed(2)}</td>

								</tr>
							);
						})}
						<tr className="summary">
							<td colSpan={2} className="caption" style={{ "border-top": "dotted" }}>
								Total Due:
							</td>
							<td style={{ "border-top": "dotted" }}>{receipt.subtotal_amount ? receipt.subtotal_amount.toFixed(2): ''}</td>
						</tr>
						{receipt.discount_type === 1 ? (
						<tr className="summary" display='none'>
							<td colSpan={2} className="caption">
								Discount (10%):
							</td>
							<td className="value">{receipt.discount_rate ? receipt.discount_rate.toFixed(2) : ''}</td>
						</tr>
						) : ''
						}
						{receipt.discount_type === 2 ? (
						<tr className="summary" display='none'>
							<td colSpan={2} className="caption">
								Discount ($):
							</td>
							<td className="value">{receipt.discount_amount ? receipt.discount_amount.toFixed(2) : ''}</td>
						</tr>
						) : ''
						}
						<tr className="summary">
							<td colSpan={2} className="caption">
								Cash Payment:
							</td>
							<td className="value">{receipt.amount_received ? receipt.amount_received.toFixed(2): ''}</td>
						</tr>
						<tr className="summary">
							<td colSpan={2} className="caption">
								Your Change:
							</td>
							<td className="value">{receipt.change_amount ? receipt.change_amount.toFixed(2):''}</td>
						</tr>

					</tbody>
				</table>
				<p>
					<b>{receipt.footer_text1}</b>
				</p>
				<p>{receipt.footer_text2}</p>
			</div >
		);
	}
}

export default ReceiptBody;
