import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Receipt from "../component/Receipt/";
import Header from "../component/Header/";

import "./Invalid.css";

class Invalid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			found: false,
			receipt: {
				header_text1: "XXXX",
				header_text2: "Not Found",
				"sales_order_number": "Not Found",
				total_amount: 0,
				ReceiptItems: [],
				footer_text1: "This error has been logged for the administrator's attention.",
				footer_text2: "Thank you!"
			},
		};
	}

	render() {
		let { receipt } = this.state;
		return (
			<div className="App">
				<Header receipt={receipt} />
				<Receipt receipt={receipt} />
			</div>
		);
	}
}

export default withRouter(Invalid);
