import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Receipt from "../component/Receipt/";
import Header from "../component/Header/";
import { getReceipt } from "../services/receipt";

import "./DefaultReceipt.css";

class DefaultReceipt extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			found: false,
			receipt: {
				total_amount: 0,
				ReceiptItems: [],
			},
		};
	}

	async componentDidMount() {
		let { id } = this.props.match.params;
		if (id) {
			let response = await getReceipt(id);
			if (response && response.status && response.status === 404) {
				// got an explicit 404.
				this.setState({ loading: false, receipt: null });
			} else {
				// response could be valid, or null if the request failed (e.g. timeout)
				this.setState({ loading: false, receipt: response });
			}
		} else {
			//no id specified
			//window.location.href = 'https://www.upos.com.sg/?re=receipts';
		}
	}

	render() {
		let { receipt } = this.state;
		receipt = receipt ? receipt : {
			header_text1: "0000",
			header_text2: "Not Found",
			"sales_order_number": "Not Found",
			total_amount: 0,
			ReceiptItems: [],
			footer_text1: "This error has been logged for the administrator's attention.",
			footer_text2: "Thank you!"
		}
		return (
			<div className="App">
				<Header receipt={receipt} />
				<Receipt receipt={receipt} />
			</div>
		);
	}
}

export default withRouter(DefaultReceipt);
