/**
 * request - web request extended interface
 * Detailed api documentation: https://github.com/umijs/umi-request
 */
import { extend } from "umi-request";

const errorHandler = (error) => {
	let { response } = error;
	if (response && response.status) {
		response.serverResponse = error.data;
	}

	return response;
};

const request = extend({
	errorHandler,
});

export default request;
